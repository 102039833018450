<template>
  <div class="btn-group" :class="{'opacity-button': !isMe}">
    <button class="btn btnoutline dropdown-toggle" type="button" id="dropdownMenuClickableInside1" data-bs-toggle="dropdown" data-bs-auto-close="outside"
    ref="datepickerDropDown">
      <strong>{{$t("sabre.search-panel.when")}}</strong>
      <span>
        <i class="fa-regular fa-calendar"></i>
      </span>
      <small>{{ dateString }}</small>
    </button>
    <div :class="dropdownClassName" aria-labelledby="dropdownMenuClickableInside1">
      <search-date-range-picker @close="closeDropdown"/>
      <button class="cleanup_close" v-if="device==='mobile'" @click="() => closeDropdown()"><i class="fas fa-times"></i></button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import dropdownOpenState from '@/sabre/common/mixins/dropdownOpenState';

export default {
  name: 'fh-date-range',
  mixins: [dropdownOpenState],
  components: {
    SearchDateRangePicker: () => import('./FHDateRangePicker'),
  },
  props: {
    componentName: {
      type: String,
      default: 'calendar',
    },
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      fromDate: 'GET_FH_FROM_DATE',
      toDate: 'GET_FH_TO_DATE',
    }),
    dateString() {
      const { fromDate, toDate } = this;
      return `${fromDate ? this.convertDateFormat(fromDate) : ''} - ${toDate ? this.convertDateFormat(toDate) : ''}`;
    },
    dropdownClassName() {
      const { showed, device } = this;
      return `dropdown-menu fromwheredropdown ${showed ? 'show' : ''}${device === 'mobile' ? ' fixedbox' : ''}`;
    },
  },
  data: () => ({
    showed: false,
  }),
  methods: {
    closeDropdown() {
      this.showed = false;
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.datepickerDropDown).hide();
      this.$emit('closed', this.componentName);
    },
    openDropdown() {
      this.showed = true;
    },
    convertDateFormat(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
  },
};

</script>

<style scoped>
.fromwheredropdown:not(.fixedbox) {
  top: auto !important;
  left: auto !important;
}
.dropdown-menu.fromwheredropdown:not(.fixedbox) {
  max-width: 730px;
  min-width: 730px;
}
</style>
